<template>
  <simpleNotifyPage :action="action" barType="error">
    <template v-slot:subtitle> 你的申請未通過, 因為... </template>
    <template v-slot:text-label> 供應商審核狀態： </template>
    <template v-slot:text-value> 未通過 </template>
    <template v-slot:btn-text> 修改並重新申請 </template>
  </simpleNotifyPage>
</template>

<script>
export default {
  components: {
    simpleNotifyPage: () =>
      import("@/modules/base/views/notifyPage/simpleNotifyPage.vue"),
  },
  computed: {
    layoutProviderName() {
      return this.$store.getters[`base/layoutProviderName`];
    },
    layoutProviderId() {
      return this.$store.getters[`base/layoutProviderId`];
    },
  },
  methods: {
    action() {
      this.$router.push({
        name: "store-register",
        params: { providerId: this.layoutProviderId },
        query: { forceUpdate: true },
      });
    },
  },
};
</script>